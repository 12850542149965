import './bootstrap'
import '../css/app.css'

import type { DefineComponent } from 'vue'
import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
/* @ts-ignore */
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m'
import smoothscroll from 'smoothscroll-polyfill'

createInertiaApp({
    progress: {
        color: '#FACC15',
    },
    title: (title) => `${title} - Capacity Planner`,
    resolve: (name) => {
        const pages = import.meta.glob<DefineComponent>('./Pages/**/*.vue', {
            eager: false,
        })
        return pages[`./Pages/${name}.vue`]()
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })

        app.use(plugin)
            .use(ZiggyVue, (window as any).Ziggy)
            .mount(el)
    },
})

smoothscroll.polyfill()
